import { Splide } from '@splidejs/splide';
import { AutoScroll } from '@splidejs/splide-extension-auto-scroll';

const cloneLogoSplide1 = document.querySelector('.logoSplide1 .splide__slide');
const cloneLogoSplide2 = document.querySelector('.logoSplide2 .splide__slide');

const splideList1 = document.querySelector('.logoSplide1 .splide__list');
const splideList2 = document.querySelector('.logoSplide2 .splide__list');

for (let i = 0; i < 5; i++) {
    const clonedElement1 = cloneLogoSplide1.cloneNode(true);
    splideList1.appendChild(clonedElement1);

    const clonedElement2 = cloneLogoSplide2.cloneNode(true);
    splideList2.appendChild(clonedElement2);
}

const logoSplide1 = new Splide( '.logoSplide1', {
  type: 'loop',
  arrows: false,
  autoWidth: true,
  pagination: false,
  drag: false,
  autoScroll: {
    speed: 1,
    pauseOnFocus: false,
    pauseOnHover: false,
  },
} );

const logoSplide2 = new Splide( '.logoSplide2', {
  type: 'loop',
  arrows: false,
  autoWidth: true,
  pagination: false,
  drag: false,
  autoScroll: {
    speed: 1,
    pauseOnFocus: false,
    pauseOnHover: false,
  },
} );

logoSplide1.mount({AutoScroll});
logoSplide2.mount({AutoScroll});
